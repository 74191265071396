<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-27 11:53:53
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-02 10:51:11
-->

<template>
  <el-card class="box">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus"
      >新增</el-button
    >
  </el-card>
  <el-card class="box data">
    <el-table :data="tableData">
      <!-- <el-table-column label="编号" prop="id" width="80"> </el-table-column> -->
      <el-table-column label="名称" prop="name" width="160"> </el-table-column>
      <el-table-column label="提示" prop="tip"> </el-table-column>
      <el-table-column label="可写备注" width="100">
        <template #default="scope">
          {{ scope.row.is_allow_remark ? "是" : "否" }}
        </template>
      </el-table-column>
      <el-table-column label="备注输入描述" prop="remark_hint">
      </el-table-column>
            <el-table-column label="可搜索"  width="80">
        <template #default="scope">
           {{scope.row.is_search?'是':''}}
        </template>
      </el-table-column>
      <el-table-column label="状态"  width="80">
        <template #default="scope">
          <el-tag
            :type="scope.row.is_stop ? 'danger' : 'success'"
            size="small"
            >{{ scope.row.is_stop ? "停用" : "正常" }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="create_at"  width="180"> </el-table-column>
      <el-table-column label="操作" width="140" fixed="right">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)"
            >修改</el-button
          >
          <el-popconfirm title="去定要删除？" @confirm="onDelete(scope.row)">
            <template #reference>
              <el-button type="danger" link size="small" class="text-danger"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <edit-askfor :item="current" :reload="loadData"></edit-askfor>
</template>
<script>
import EditAskfor from "./edit_askfor.vue";

export default {
  components: {
    EditAskfor,
  },
  data() {
    return {
      activeName: "bed",
      current: null,
      tableData: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.$http.post("admin/v1/askfor").then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },

    /**
     * 新增、修改
     */
    onEdit(item) {
      this.current = item ? item : {};
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("admin/v1/askfor/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },
  },
};
</script>