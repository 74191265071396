<!--
 * @Description: 编辑入住要求
 * @Author: 琢磨先生
 * @Date: 2022-05-27 11:54:00
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-12 04:32:18
-->


<template>
  <el-dialog
    v-model="drawer"
    :title="title"
    width="600px"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="名称" prop="name">
        <el-col :span="14">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="描述提示" prop="tip">
        <el-col :span="14">
          <el-input v-model="form.tip" placeholder="描述提示"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="可填写备注">
        <el-switch v-model="form.is_allow_remark"></el-switch>
      </el-form-item>
      <el-form-item label="备注输入框说明" v-if="form.is_allow_remark">
        <el-col :span="16">
          <el-input v-model="form.remark_hint" placeholder=""></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="停用">
        <el-switch v-model="form.is_stop"></el-switch>
      </el-form-item>
      <el-form-item label="可搜索">
        <el-switch v-model="form.is_search"></el-switch>
        <div class="help-block">可以作为搜索房源参数、不宜设置过多项</div>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="onSubmit"
          :disabled="loading"
          :loading="loading"
          >确定</el-button
        >
        <el-button @click="cancelClick" :disabled="loading">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      drawer: false,
      title: "编辑应用",
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        number: [
          {
            required: true,
            message: "请输入人数",
            trigger: "blur",
          },
          {
            pattern: "^[1-9]$",
            message: "正整数",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["item", "reload"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.drawer = true;
          this.title = "新增入住要求";
          this.form = Object.assign({}, this.item);
          if (this.item.id) {
            this.title = "修改入住要求";
          }
        }
      },
    },
  },

  methods: {
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post("/admin/v1/askfor/edit", this.form)
            .then((res) => {
              this.loading = false;
              if (res.code == 0) {
                this.drawer = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                if (this.reload) {
                  this.reload();
                }
              }
            })
            .cath(() => {
              this.loading = false;
            });
        }
      });
    },
    /**
     * 取消
     */
    cancelClick() {
      this.drawer = false;
      if (this.reload) {
        this.reload();
      }
    },
    /**
     * 规格选择变动
     */
    sizeChange(val) {
      console.log(val);
    },
  },
};
</script>